import React from "react";
import "./App.css";
import logoEscondida from "./assets/logo_escondida.png";
import logoSpence from "./assets/logo_spence.png";
const App = () => {
  return (
    <div className="App">
      <div className="select-box-container">
        <div className="select-box">
          <div className="select-options">
            <div className="select-title">
              Seleccione la operacion a la cual desea ingresar
            </div>
            <div className="select-options-buttons">
            <a
                href="/escondida/login"
                target="_blank"
                rel="noopener noreferrer"
                className="option-container">
                <img
                  src={logoEscondida}
                  alt=""
                />
              </a>
              <hr
                style={{
                  width: "100%",
                  borderColor: "rgba(218, 218, 218, 0.5)",
                }}
              />
              <a
                href="/spence/login"
                target="_blank"
                rel="noopener noreferrer"
                className="option-container">
                <img
                  src={logoSpence}
                  alt=""
                  style={{ height: "40%", marginTop: "1.25em" }}
                />
              </a>
              {/* <div
                onClick={() => window.location.replace("/spence/login")}
                className="option-container">
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
